/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* Gravity Forms Styling */

.gform_legacy_markup_wrapper textarea.large {
  @include media-breakpoint-up(md) {
    height:150px!important;
  }
}

.gform_wrapper.gravity-theme .gfield input.large, .gform_wrapper.gravity-theme .gfield select.large, textarea {
  background: rgba(255,255,255,0.5)!important;
  border: 1px solid white!important;
  outline: none!important;
  border-radius: 3px!important;
}
.no-label label {
  display: none!important;
}
#gform_2 input[type="text"] {
  background: #fff!important;
  border-radius: 10px!important;
  padding: 10px;
  color: #21BD30;
  font-family: "Avenir Next Heavy", Helvetica, Arial, sans-serif;

  &::placeholder {
    color: #21BD30;
    font-family: "Avenir Next Heavy", Helvetica, Arial, sans-serif;
  }
}
input#gform_submit_button_2 {
  color:#fff;
  background: #21BD30;
  padding: 10px 15px;
  width: 100%;
  border-radius: 10px;
  border: 0;
  font-family: "Avenir Next Heavy", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}