// Theme Colors/Branding
$brand-primary: #000;
$brand-primary-rgb: 0,0,0;
$brand-primary-offset: #000;
$brand-secondary: #000;
$dark: #000;
$light: #f5f5f5;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: #CDB380;
$accent-2: #1459BA;
$body-font: #222;
$error: #d81e00;